@import '../../providers/styles/ThemeVariables.scss';

.container {
  @include themify($themes) {
    background: themed('brightGradient');
  }

  position: sticky;
  top: 0;
  z-index: 2;
  height: 56px;
  padding: 0 28px;
  display: flex;
  align-items: center;
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.buttonsWrapper {
  display: flex;
  font-size: large;
  gap: 20px;
  margin-left: 20px;
  align-items: center;

  button {
    background-color: transparent;
    border: none;
    font: inherit;

    @include themify($themes) {
      color: themed('brightInverted');
    }

    &:after {
      backface-visibility: hidden;
      border: 1px solid rgba(#fb2b89, 0);
      bottom: 0;
      content: " ";
      display: block;
      margin: 0 auto;
      position: relative;
      transition: all 280ms ease-in-out;
      width: 0;
    }

    &:hover:after {
      backface-visibility: hidden;
      border-color: #fc8846;
      transition: width 350ms ease-in-out;
      width: 100%;
    }

    &.active:after {
      backface-visibility: hidden;
      border-color: #fc8846;
      transition: width 350ms ease-in-out;
      width: 100%;
    }
  }
}
