@import '../../providers/styles/ThemeVariables.scss';

.pageWrapper {
  margin: 0 100px;
  user-select: none;

  @include themify($themes) {
    color: themed('brightInverted');
  }

  .topWrapper {
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 50%));

    @media (max-width: 800px) {
      grid-template-columns: 100%;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;

    .bigTitle {
      font-size: 64px;
    }

    .smallTitle {
      font-size: 18px;
    }
  }

  .imageWrapper {
    img {
      width: 100%;
    }
  }


  .lessonsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 44px;
    padding-top: 80px;

    .arrowDown path {
      @include themify($themes) {
        stroke: themed('brightInverted');
      }
    }
  }
}
