@import '../../providers/styles/ThemeVariables.scss';

.arduinoPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 80px;
  padding-bottom: 20px;
  position: relative;

  @include themify($themes) {
    color: themed('brightInverted');
  }

  .title {
    font-size: 44px;
    margin-left: 8px;
  }

  .componentWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 60px;
    margin-top: 22px;
    height: 240px;

    @media (max-width: 800px) {
      grid-template-columns: 100%;
    }
  }

  .buttonRun {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    padding: 0 8px;
    border-radius: 8px;
    gap: 8px;
    border: 1px solid #ffc27a;
    width: 80px;
    margin-top: 40px;

    &.active,
    &:hover {
      border: 1px solid #ffc27a;

      @include themify($themes) {
        background: themed('pale');
      }
    }

    &:hover {
      box-shadow: 0 12px 48px rgba(255, 194, 122, 0.26);
    }
  }
}

.subtitle {
  font-size: 22px;
  margin-bottom: 12px;
  text-align: center;
}

.loaderWrapper {
  height: 100%;
}
