.toggle-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.toggle-slot {
  position: relative;
  height: 30px;
  width: 68px;
  border: 3px solid #e4e7ec;
  border-radius: 10em;
  background-color: white;
  box-shadow: 0px 0px 10px #e4e7ec;
  transition: background-color 250ms;
}

.toggle-checkbox:checked~.toggle-slot {
  background-color: #374151;
}

.toggle-button {
  transform: translate(2.4em, 0.1em);
  position: absolute;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background-color: #ffeccf;
  box-shadow: inset 0px 0px 0px 0.2em #ffbb52;
  transition: background-color 250ms, border-color 250ms, transform 500ms cubic-bezier(.26, 2, .46, .71);
}

.toggle-checkbox:checked~.toggle-slot .toggle-button {
  background-color: #485367;
  box-shadow: inset 0px 0px 0px 0.2em white;
  transform: translate(0.2em, 0.1em);
}

.sun-icon {
  position: absolute;
  height: 24px;
  width: 24px;
  color: #ffbb52;
}

.sun-icon-wrapper {
  position: absolute;
  height: 24px;
  width: 24px;
  opacity: 1;
  transform: translate(0.3em, 0.2em) rotate(15deg);
  transform-origin: 50% 50%;
  transition: opacity 150ms, transform 500ms cubic-bezier(.26, 2, .46, .71);
}

.toggle-checkbox:checked~.toggle-slot .sun-icon-wrapper {
  opacity: 0;
  transform: translate(2.4em, 0.1em) rotate(0deg);
}

.moon-icon {
  position: absolute;
  height: 24px;
  width: 24px;
  color: white;
}

.moon-icon-wrapper {
  position: absolute;
  height: 24px;
  width: 24px;
  opacity: 0;
  transform: translate(0.2em, 0.2em) rotate(0deg);
  transform-origin: 50% 50%;
  transition: opacity 150ms, transform 500ms cubic-bezier(.26, 2.5, .46, .71);
}

.toggle-checkbox:checked~.toggle-slot .moon-icon-wrapper {
  opacity: 1;
  transform: translate(2.4em, 0.1em) rotate(-15deg);
}
