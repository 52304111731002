.listWrapper {
  margin-top: 100px;
  display: grid;
  flex-direction: column;
  position: relative;
  padding-bottom: 80px;
  margin-bottom: 40px;

  grid-template-columns: repeat(2, minmax(0, 50%));
  grid-gap: 40px;

  @media (max-width: 800px) {
    grid-template-columns: 100%;
  }

  .pagination {
    display: flex;
    gap: 8px;
    font-size: 24px;
    position: absolute;
    bottom: 0;
    right: 20px;

    li {
      display: flex;
      background-color: #ffffff;
      height: 40px;
      width: 40px;
      color: #fc8846;
      border-radius: 50px;
      box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
      margin: 0;

      a {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
      }

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.3) 0 19px 38px, rgba(0, 0, 0, 0.22) 0 15px 12px;
      }

      &.disabled {
        color: #f1c9b6;
        background-color: #ffffff;
        box-shadow: none;
      }

      &.selected {
        color: #ffffff;
        background-color: #fc8846;
      }
    }
  }
}
