.logoWrapper {
  padding: 4px;
  display: flex;
  background: white;
  gap: 4px;
  border-radius: 8px;
  height: 30px;
  align-items: center;
  font-weight: 700;
  user-select: none;
}

.robo {
  color: #2a31bf;
}

.learnWrapper {
  border: #fc8846 2px solid;
  border-radius: 4px;

  .learn {
    color: #fc8846;
    margin: 0;
    padding: 2px;
  }
}
