@import '../../providers/styles/ThemeVariables.scss';

.lessonPage {
  margin: 40px 80px;
  padding-bottom: 20px;

  @include themify($themes) {
    color: themed('brightInverted');
  }

  .title {
    font-size: 44px;
    margin-left: 8px;
  }

  .contentWrapper {
    display: grid;
    grid-template-columns: 65% 35%;
    grid-gap: 40px;
    margin-top: 22px;

    @media (max-width: 800px) {
      grid-template-columns: 100%;
    }

    .videoWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      iframe {
        width: 100%;
        height: 500px;
        min-width: 800px;
        border-radius: 14px;
        margin-bottom: 20px;
      }
    }

    .subtitle {
      font-size: 16px;
    }

    .skillsWrapper {
      display: flex;
      flex-direction: column;
      gap: 80px;
    }
  }

  .buttonWrapper {
    text-decoration: none;
  }

  .arduinoWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
  }

  .arduinoButton {
    padding: 8px 12px;
    display: flex;
    background: white;
    gap: 4px;
    border-radius: 8px;
    align-items: center;
    font-weight: 700;
    border: none;

    @include themify($themes) {
      color: themed('bright');
      background: themed('brightInverted');
    }

    &:hover {
      @include themify($themes) {
        box-shadow: 0px 0px 10px themed('brightInverted');
      }
    }
  }
}
