.spinnerWrapper {
  display: flex;
  width: 100%;
  justify-content: center;

  .spinner {
    margin: 0 10px;
    border-radius: 50%;
    animation: rotating-spinner 1s linear infinite;
    width: 80px;
    height: 80px;
    border: 1px solid #f3f3f3;
    border-top: 4px solid #fc8846;

    @keyframes rotating-spinner {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }
}
